import {useEffect, useState} from "react";
import {fetchTasks} from "../../../utils/api/tasks";
import {Link} from "react-router-dom";
import AttentionIcon from "./AttentionIcon";
import Preloader from "../../General/Preloader";
import {useAuth} from "../../../hooks/useAuth";
import {loggedInUser} from "../../../utils/helpers";

export default function OverviewDashboard() {
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { makeAuthenticatedRequest, accessToken} = useAuth();

    useEffect(()=> {
        if(accessToken) {
            if(tasks.length === 0) {
                fetchTasks(accessToken, makeAuthenticatedRequest, setTasks, setIsLoading);
            }
        }
    },[makeAuthenticatedRequest]);

    const listUndoneTasks = tasks?.filter(function(task) {
        if(task.state === "open") {
            return task;
        }
    }).map(task => (
        <li key={task.task.id} className={`mb-[19px] w-full sm:flex-row justify-between flex flex-col text-ere-grey-alternative relative has-divider pb-[16px] sm:pb-[unset] ${(task.task.risk.risk_value > 66) ? "" : ""}`} >
            <Link id={`erecht24-task-${task.id}`} to={`/tasks/${task.id}`} state={{current_task: task}} className="float-left flex items-center"><AttentionIcon riskvalue={task.task.risk.risk_value} isDashboard={true} />  <span  className="ml-[12px] w-fit break-normal">{task.task.title}</span></Link>
            <Link id={`erecht24-task-${task.id}`} to={`/tasks/${task.id}`} state={{current_task: task}} className="text-ere-link-blue lg:self-end md:self-center items-center mt-[10px] md:mt-0 min-w-[112px] text-xs uppercase underline block md:text-end ml-[32px] md:ml-[unset]">Zur Aufgabe</Link>
        </li>
    ))

    return(
        <>
            {isLoading ?
                <div className={`!grow m-0 p-3 flex flex-col  bg-white rounded light-blue-border md:p-[22px] md:flex-row xl:justify-between lg:h-fit lg:w-[71%] md:!min-h-[345px]`}>
                    <div className="w-[100%] lg:flex-row">
                        <div className="flex flex-col items-center h-full justify-center w-full">
                            <Preloader/>
                        </div>
                    </div>
                </div>
            :
                <div className={`!grow m-0 p-3 flex flex-col  bg-white rounded light-blue-border md:p-[22px] md:flex-row xl:justify-between lg:h-fit  md:!min-h-[345px] ${(loggedInUser && !loggedInUser.editable_by_data_protection_officer) ? 'lg:w-[71%]' : 'lg:w-[55%]'}`}>
                    <div className="w-[100%] lg:flex-row">
                        <div className="flex flex-col md:flex-none md:flow-root md:items-center mb-[24px] relative">
                            <p className="absolute right-0 text-sm text-ere-disabled-text md:hidden">({listUndoneTasks.length})</p>
                            <>
                                <h1 className="float-left text-[20px] text-ere-content-blue font-bold">Meine dringendsten Aufgaben</h1>
                                <Link to={"/tasks"} className="float-right text-ere-link-blue text-xs uppercase underline mt-[10px] font-bold">Alle
                                    Aufgaben ansehen
                                </Link>
                            </>
                        </div>
                        <ul className="list-disc text-ere-grey-alternative w-full text-sm dashboard-task-list">
                            {listUndoneTasks.length !== 0 ?
                                listUndoneTasks.slice(0, 6)
                            :
                                <>Es gibt keine Aufgaben zu erledigen.</>
                            }
                        </ul>
                    </div>
                </div>
            }
        </>
    );
}