import man from "../../assets/images/lex2.jpg";
import certificate from "../../assets/images/tuv-zertifikat.png";
import {loggedInUser} from "../../utils/helpers";

export default function AppointmentDashboard() {
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        (loggedInUser && !loggedInUser.editable_by_data_protection_officer) ?
            <div className="w-full md:!max-w-[280px] lg:!w-[280px] !h-[355px] s:!h-[315px] md:!h-full md:!min-h-[345px] relative bg-white rounded flex flex-col overflow-hidden p-[22px] light-blue-border">

                        <h1 className="text-[20px] font-bold text-ere-content-blue leading-[24px] w-max z-10 mb-[8px] ">Sie brauchen Hilfe?</h1>
                        <span className=" text-sm font-normal text-ere-grey-alternative leading-normal  z-10 text-left">Mehr Beratung, einen eigenen Datenschutzbeauftragten, ein ausführliches Onboarding? Kein Problem, mit dem DatenschutzPro Beratungspaket inkl. DSB sind Sie rundum gut beraten.</span>
                        <div className="h-20 flex-col align-top text-ere-grey-alternative">
                            <div className="text-xs mt-[10px] lg:mt-0 xl:mt-[10px] flex flex-col leading-normal">
                                <span className="font-semibold">Lev Lexow<br/></span>
                                <span className="font-normal break-keep z-10 -mt-[1px]">TÜV-zertifizierter DSB</span>
                                <img className="w-[100px] h-[37px] shadow-xs  z-20" src={process.env.REACT_APP_URL + certificate} alt="TÜV-zertifizierter DSB"/>
                            </div>
                        </div>
                        <img className="w-[174px] -bottom-[70px] absolute -right-[11px] scale-[0.88]" src={process.env.REACT_APP_URL + man} alt="Lev Lexow - TÜV-zertifizierter DSB"></img>
                        <button id="erecht24-dashboard-appointment"
                                className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover hover:bg-white z-2 absolute text-sm  uppercase tracking-[0.233px] font-black bottom-[24px] left-[24px]"
                                onClick={() => openInNewTab("https://calendly.com/legaltrust")}
                        >
                            Jetzt Upgrade buchen
                        </button>
            </div>
            :
            <div
                className="w-full md:!max-w-[280px] lg:!w-[280px] !h-[325px]  md:!h-full md:!min-h-[345px] relative bg-white rounded flex flex-col overflow-hidden p-[22px] light-blue-border">
                <h1 className="text-[20px] font-bold text-ere-content-blue leading-[24px] w-max z-10 mb-[8px] ">DatenschutzPro
                    Beratung</h1>
                <span className=" text-sm font-normal text-ere-grey-alternative leading-normal  z-10 text-left">Als Kunde des DatenschutzPro Beratungspakets können Sie ab sofort Ihr Onboarding und Ihre Beratungsstunden bei Legaltrust buchen sowie Soforthilfe bei DSGVO Notfällen anfordern</span>
                <div className="h-20 flex-col align-top text-ere-grey-alternative">
                    <div className="text-xs mt-[10px] lg:mt-0 xl:mt-[10px] flex flex-col leading-normal">
                        <span className="font-semibold">Lev Lexow<br/></span>
                        <span className="font-normal break-keep z-10 -mt-[1px]">TÜV-zertifizierter DSB</span>
                        <img className="w-[100px] h-[37px] shadow-xs  z-20"
                             src={process.env.REACT_APP_URL + certificate} alt="TÜV-zertifizierter DSB"/>
                    </div>
                </div>
                <img className="w-[174px] -bottom-[70px] absolute -right-[11px] scale-[0.88]"
                     src={process.env.REACT_APP_URL + man} alt="Lev Lexow - TÜV-zertifizierter DSB"></img>
                <button id="erecht24-dashboard-appointment"
                        className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover hover:bg-white z-2 absolute text-sm  uppercase tracking-[0.233px] font-black bottom-[24px] left-[24px]"
                        onClick={() => openInNewTab("https://calendly.com/legaltrust")}
                >
                    Jetzt Termin buchen
                </button>
            </div>
    )

}